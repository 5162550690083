<ng-select
  #select
  [items]="sortData()"
  [bindValue]="bindValue"
  [bindLabel]="sortField"
  class="custom-select-header dynamic-select dynamic-select-header-custom _x_mt-1 _x_h-full _x_cursor-pointer _x_shadow-dark-100"
  [multiple]="multiple"
  [closeOnSelect]="closeOnSelect"
  [maxSelectedItems]="maxSelectedItems"
  [searchable]="searchable"
  [clearable]="showClearButton"
  [virtualScroll]="true"
  [placeholder]="placeholder"
  [loading]="isLoading"
  (search)="onSearch($event)"
  (blur)="onTouched()"
  (scrollToEnd)="onScrollToEnd()"
  (clear)="onClear()"
  (open)="onOpen()"
  (close)="onClose()"
  [(ngModel)]="value"
  [disabled]="isDisabled"
  [notFoundText]="''"
  dropdownPosition="auto">
  <ng-template ng-header-tmp *ngIf="customSearch">
    <div
      class="dropdown-header-custom _x_relative _x_mt-5 _x_flex _x_h-24 _x_items-center _x_rounded-8 _x_border-1 _x_border-dark-200 _x_pl-11 _x_pr-11 _x_font-medium">
      <i
        class="_x_icon-search _x_absolute _x_left-0 _x_ml-10 _x_flex _x_h-12 _x_w-12 _x_items-center _x_text-7 _x_text-dark-600"></i>
      <input
        class="focus:_x_outline-0 _x_h-full _x_w-full _x_border-0 _x_bg-transparent _x_px-15 _x_font-medium _x_outline-none focus:_x_outline-none focus-visible:_x_outline-none"
        placeholder="მოძებნე ქალაქი"
        type="text"
        (input)="select.filter($event.target.value)" />
      <div
        class="_x_border-1 _x_border-dark-100"
        *ngIf="!isLoading && addOptionButton && !select.viewPortItems.length"
        (click)="addOption()">
        დამატება
      </div>
    </div>
  </ng-template>
  <ng-template ng-header-tm>
    <hr class="_x_border-top _x_border-dark-100" />
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items">
    <div style="width: 100%" class="ng-value" *ngFor="let item of items | slice: 0 : 2; let last = last">
      <span class="ng-value-label">
        {{ item[sortField] }}
        <ng-container *ngIf="!last">,&nbsp;</ng-container>
      </span>
      <span class="_x_mr-5" style="color: #918f94; float: right" *ngIf="required">*</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2">
      <span class="ng-value-label">,&nbsp;{{ items.length - 2 }} სხვა...</span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$">
    <ng-container *ngIf="!optionTemplate">
      <div *ngIf="multiple" class="custom-checkbox custom-checkbox-selected-blue mr-3">
        <input
          type="checkbox"
          id="custom-checkbox-{{ item.id }}"
          class="custom-checkbox-control"
          [checked]="item$.selected" />
        <label
          (click)="$event.stopPropagation()"
          for="custom-checkbox-{{ item.id }}"
          class="custom-checkbox-label"></label>
      </div>
      {{ item[sortField] }}
    </ng-container>
    <ng-container *ngTemplateOutlet="optionTemplate; context: { item: item }"></ng-container>
  </ng-template>
  <ng-template ng-footer-tmp>
    <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
  </ng-template>
</ng-select>
