import { Injectable } from '@angular/core';

interface ElementWithId {
  id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor() {}

  public clearOrderIdsFromLocalStorage(): void {
    localStorage.getItem('orderExternalId') && localStorage.removeItem('orderExternalId');
    localStorage.getItem('orderStrId') && localStorage.removeItem('orderStrId');
  }

  public moveElementToStartOfTheArray<T extends ElementWithId>(array: T[], elementId: number): T[] {
    const elementIndex = array.findIndex(({ id }) => id === elementId);
    if (elementIndex > -1) {
      const [element] = array.splice(elementIndex, 1);
      array.unshift(element);
    }
    return array;
  }

  public findAdditionalElementBetweenTwoArrayWithId<T extends ElementWithId>(array1: T[], array2: T[]): T | undefined {
    const set = new Set(array1?.map(({ id }) => id));
    return array2.find(({ id }) => !set.has(id));
  }

  public getCurrentDateGMT4(): string {
    const now = new Date();

    // Adjust to GMT+4 by adding the offset in milliseconds
    const gmt4Time = new Date(now.getTime() + 4 * 60 * 60 * 1000);

    // Get the ISO string (in UTC) and remove the 'Z' at the end
    const isoString = gmt4Time.toISOString().slice(0, -1); // e.g., "2024-11-25T12:34:56.789"

    // Pad the milliseconds to have 3 digits (adds four zeros)
    const [datePart, timePart] = isoString.split('T');
    const [time, millis] = timePart.split('.');
    const fractionalSeconds = (millis || '0').padEnd(3, '0');

    // Append '+04:00' for GMT+4 time zone offset
    const formattedDate = `${datePart}T${time}.${fractionalSeconds}Z`;

    return formattedDate;
  }
}
