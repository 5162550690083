import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { CatalogHttp } from 'lib-core';
import { select, Store } from '@ngrx/store';
import { getUserStatus } from '../store/reducers/auth.reducer';

export interface SearchHistoryItem {
  id: number;
  searchText: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchHistoryService {
  private localStorageKey = 'searchHistory';
  private maxHistoryItems = 5;
  private isAuthorized: boolean = false;
  userStatus$: Observable<any> = this.store.pipe(select(getUserStatus));

  public searchHistorySubject = new BehaviorSubject<SearchHistoryItem[]>([]);

  constructor(
    private catalogHttp: CatalogHttp,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store
  ) {
    this.userStatus$.subscribe(status => {
      this.isAuthorized = status;
      if (this.isAuthorized) {
        this.clearLocalStorage();
      }
      this.getSearchHistory();
    });
  }

  public getSearchHistory(): void {
    if (this.isAuthorized) {
      this.catalogHttp.getSearchHistory().subscribe({
        next: (history: SearchHistoryItem[]) => {
          this.searchHistorySubject.next(history);
        },
        error: error => {
          console.error('Error fetching search history:', error);
          this.searchHistorySubject.next([]);
        }
      });
    } else {
      const history = this.getLocalStorageHistory();
      this.searchHistorySubject.next(history);
    }
  }

  public addSearchTerm(searchText: string): void {
    searchText = searchText.trim();
    if (!searchText) {
      return;
    }
    if (this.isAuthorized) {
      this.getSearchHistory();
    } else {
      let history = this.getLocalStorageHistory();
      history = history.filter(item => item.searchText !== searchText);
      const newItem: SearchHistoryItem = {
        id: Date.now(),
        searchText
      };
      history.unshift(newItem);
      if (history.length > this.maxHistoryItems) {
        history = history.slice(0, this.maxHistoryItems);
      }

      this.setLocalStorageHistory(history);
      this.searchHistorySubject.next(history);
    }
  }

  public deleteSearchTerm(id: number): void {
    if (this.isAuthorized) {
      this.catalogHttp.deleteSearchHistoryTerm(id).subscribe({
        next: () => {
          this.getSearchHistory();
        },
        error: error => {
          console.error('Error deleting search term:', error);
        }
      });
    } else {
      let history = this.getLocalStorageHistory();
      history = history.filter(item => item.id !== id);
      this.setLocalStorageHistory(history);
      this.searchHistorySubject.next(history);
    }
  }

  public clearSearchHistory(): void {
    if (this.isAuthorized) {
      this.catalogHttp.deleteAllSearchHistory().subscribe({
        next: () => {
          this.getSearchHistory();
        },
        error: error => {
          console.error('Error clearing search history:', error);
        }
      });
    } else {
      this.clearLocalStorage();
      this.searchHistorySubject.next([]);
    }
  }

  /**
   * Retrieve search history from localStorage.
   */
  private getLocalStorageHistory(): SearchHistoryItem[] {
    if (isPlatformBrowser(this.platformId)) {
      const historyJson = localStorage.getItem(this.localStorageKey);
      return historyJson ? JSON.parse(historyJson) : [];
    } else {
      // Return empty array if not in browser
      return [];
    }
  }

  /**
   * Save search history to localStorage.
   */
  private setLocalStorageHistory(history: SearchHistoryItem[]): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(history));
    }
  }

  /**
   * Clear search history from localStorage.
   */
  private clearLocalStorage(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(this.localStorageKey);
    }
  }
}
