import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BasketHttp } from 'lib-core';
import { MixpanelService } from 'projects/lib-core/src/lib/services/mixpanel.service';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { FbPixelService } from '../../services/fb-pixel.service';
import { WishlistService } from '../../services/wishlist.service';
import {
  addProductInWishlistAttempt,
  addProductInWishlistFail,
  addProductInWishlistSuccess,
  getWishlistProductIdsAttempt,
  getWishlistProductIdsFail,
  getWishlistProductIdsSuccess,
  getWishlistProductsAttempt,
  getWishlistProductsFail,
  getWishlistProductsSuccess,
  removeProdutFromWishlistAttempt,
  removeProdutFromWishlistFail,
  removeProdutFromWishlistSuccess
} from '../actions/wishlist.actions';
import { WishlistModel } from '../models/wishlist.model';
import { ExpressService } from '../../services/express.service';
import { getProducts } from '../actions/product.actions';
import { selectProductsByIDs } from '../reducers/product.reducer';

@Injectable()
export class WishlsitEffects {
  constructor(
    private actions$: Actions,
    private basketHttp: BasketHttp,
    private wishlistService: WishlistService,
    private store: Store<WishlistModel>,
    private route: ActivatedRoute,
    private mixpanel: MixpanelService,
    private fbPixelService: FbPixelService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private expressService: ExpressService
  ) {}

  addProductInWishlist = createEffect(() =>
    this.actions$.pipe(
      ofType(addProductInWishlistAttempt),
      mergeMap((item: any) =>
        this.basketHttp.addProductInWishlist(item.productId).pipe(
          map((res: any) => {
            this.mixpanel.trackWishlistAdd(item.productId, item.categorySlug);
            if (isPlatformBrowser(this.platformId) && item.product) {
              this.fbPixelService.addToWishlist(item.product);
            }
            this.store.dispatch(addProductInWishlistSuccess({ ...res, productId: item.productId }));
            return getWishlistProductIdsAttempt({});
          }),
          catchError(err => {
            return of(addProductInWishlistFail({ ...err, productId: item.productId }));
          })
        )
      )
    )
  );

  getWishlistProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(getWishlistProductsAttempt),
      mergeMap(data => {
        const paginationData = {
          page: data.page || this.route.snapshot.queryParams.p || 1,
          pageSize: data.pageSize || 48
        };
        return this.basketHttp
          .getWishlistProducts(paginationData, this.expressService.getDarkStoreData()?.darkStoreId)
          .pipe(
            mergeMap((res: any) => {
              const wishlistItems = res.wishlistItems;
              const ids = wishlistItems.map((item: any) => item.id);
              this.store.dispatch(getProducts({ data: ids }));
              return this.store.pipe(
                select(selectProductsByIDs, { ids }),
                filter(products => products.length > 0),
                take(1),
                map(products => {
                  const productsById = products.reduce((acc: any, product: any) => {
                    acc[product.id] = product;
                    return acc;
                  }, {});

                  const updatedWishlistItems = wishlistItems.map((item: any) => {
                    const product = productsById[item.id];
                    if (product) {
                      return { ...item, deliveryDate: product.deliveryDate };
                    }
                    return item;
                  });

                  return getWishlistProductsSuccess({ data: updatedWishlistItems });
                })
              );
            }),
            catchError(err => {
              return of(getWishlistProductsFail(err));
            })
          );
      })
    )
  );

  getWishlistProductIds = createEffect(() =>
    this.actions$.pipe(
      ofType(getWishlistProductIdsAttempt),
      mergeMap(() =>
        this.basketHttp.getWishlistProductIds().pipe(
          map((res: any) => {
            this.wishlistService.wishlistProductIds = res;
            return getWishlistProductIdsSuccess({ productIds: res });
          }),
          catchError(err => {
            return of(getWishlistProductIdsFail(err));
          })
        )
      )
    )
  );

  removeProductFromWishlist = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProdutFromWishlistAttempt),
      mergeMap((item: any) =>
        this.basketHttp.removeProductFromWishlist(item.productId).pipe(
          map((res: any) => {
            this.store.dispatch(removeProdutFromWishlistSuccess({ ...res, productId: item.productId }));
            this.store.dispatch(getWishlistProductsAttempt({}));
            return getWishlistProductIdsAttempt({});
          }),

          catchError(err => {
            return of(removeProdutFromWishlistFail({ ...err, productId: item.productId }));
          })
        )
      )
    )
  );
}
